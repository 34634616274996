import React from "react";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderTwo";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import FormTwo from "../component/contact/FormTwo";
import SectionTitle from "../elements/section-title/SectionTitle";
import ContactLocation from "../component/contact/ContactLocation";

const Contact = () => {
  return (
    <>
      <SEO title="Contact" />
      {/* <ColorSwitcher /> */}
      <main className="main-wrapper">
        <HeaderOne path={"/contact"} />
        <BcrumbBannerOne
          title="Let's Create"
          paragraph="Together, we'll bring your ideas to life with innovative solutions. Join us to transform your concepts into reality.
                "
          styleClass=""
          mainThumb="/images/banner/banner-thumb-1.png"
        />

        <div className="section section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div className="contact-form-box shadow-box mb--30">
                  <h3 className="title">Send a message</h3>
                  <FormTwo />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                  <h4 className="title">Phone</h4>
                  <p>
                    Our customer care is open from Mon-Fri, 9:00 am to 5:00 pm
                  </p>
                  <h6 className="phone-number">
                    <a href="tel:+2348167225558">(+234) 816 722 5558</a>
                  </h6>
                </div>
                <div className="contact-info mb--30">
                  <h4 className="title">Email</h4>
                  <p>
                    Our support team will get back to in 24-h during standard
                    business hours.
                  </p>
                  <h6 className="phone-number">
                    <a href="mailto:info@espresglobal.com">
                      info@espresglobal.com
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <ul className="list-unstyled shape-group-12">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Bubble"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                alt="Circle"
              />
            </li>
          </ul>
        </div>

        <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
      </main>
    </>
  );
};

export default Contact;
