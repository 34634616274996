import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderTwo";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ServiceProp from "../component/service/ServiceProp";
import ServiceSection from "../component/service/ServiceSection";
import ServiceSectionOne from "../component/service/ServiceSectionOne";
import AboutThree from "../component/about/AboutThree";
import CounterUpTwo from "../component/counterup/CounterUpTwo";

const ServiceTwo = () => {
  return (
    <>
      <SEO title="Service Two" />

      {/* <ColorSwitcher /> */}
      <main className="main-wrapper">
        <HeaderOne path={"/service-two"} />
        <BcrumbBannerOne
          title="Unlock Your Potential, <br/> How We Can Help You"
          paragraph="Stand out from the pack with a custom-built digital presence that tells your unique story"
          styleClass=""
          showBtn={true}
          mainThumb="/images/banner/banner-thumb-4.png"
        />
        <ServiceSection
          subtitle="Software Development"
          title="At Espres Global, we specialize in creating custom web, mobile, and desktop applications 
          tailored to your unique business needs. Our team of expert developers ensures robust, scalable, 
          and user-friendly solutions that drive your digital transformation and enhance user engagement."
          imagePath="/images/service/sd.svg"
        />

        <ServiceSectionOne
          subtitle="Product Plan & Strategy"
          title="Our strategic planning services help you define, prioritize, and execute 
          your product vision. Espres Global works with you to create a comprehensive roadmap, 
          ensuring your product aligns with market demands and business goals, ultimately driving 
          growth and success."
          imagePath="/images/service/prdps.svg"
        />

        <ServiceSection
          subtitle="Digital Media Marketing and SEO"
          title="Espres Global offers a holistic approach to digital marketing and SEO. Our services 
          include social media marketing, content strategy & management, and digital marketing campaigns 
          designed to boost your online presence. We optimize your content to enhance search engine rankings 
          and attract organic traffic, ensuring your brand reaches the right audience."
          imagePath="/images/service/dm.svg"
        />
        <ServiceSectionOne
          subtitle="Brand Strategy & Communications"
          title="We craft compelling brand strategies that resonate with your target audience. 
          Espres Global helps you develop a strong brand identity and consistent messaging, 
          enhancing your brand's visibility and credibility across all communication channels."
          imagePath="/images/service/brd.svg"
        />

        <ServiceSection
          subtitle="UX Research"
          title="Understanding your users is key to creating impactful products. Our UX research 
          services at Espres Global involve thorough user research, testing, and analysis to gather 
          actionable insights. We ensure your products deliver exceptional user experiences that meet 
          and exceed user expectations."
          imagePath="/images/service/ux.svg"
        />

        <ServiceSectionOne
          subtitle="Product Design"
          title="Our product design services at Espres Global focus on creating aesthetically pleasing 
          and highly functional designs. We combine creativity with technical expertise to deliver designs 
          that not only look great but also provide seamless user experiences, ensuring your product stands 
          out in the market."
          imagePath="/images/service/pd.svg"
        />
        {/* <CtaLayoutOne /> */}
        {/* <div className="section-padding-equal">
          <div className="espres-thumbnail">
            <img
              src={process.env.PUBLIC_URL + "images/banner/espres_grid.PNG"}
              alt="thumbnail"
            />
          </div>
        </div> */}
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ServiceTwo;
