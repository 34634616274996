import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderTwo from "../common/header/HeaderTwo";
import SEO from "../common/SEO";
import AboutOne from "../component/about/AboutOne";
import BannerOne from "../component/banner/BannerOne";
import BlogOne from "../component/blog/BlogOne";
import BrandOne from "../component/brand/BrandOne";
import CounterUpOne from "../component/counterup/CounterUpOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import PricingOne from "../component/pricing/PricingOne";
import ProjectOne from "../component/project/ProjectOne";
import ServicePropOne from "../component/service/ServicePropOne";
import TestimonialOne from "../component/testimonial/TestimonialOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

const Espres = () => {
  return (
    <>
      <SEO title="Espres Global" />
      {/* <ColorSwitcher /> */}
      <main className="main-wrapper">
        <HeaderTwo />
        <BannerOne />
        <div className="section section-padding-equal">
          <div className="container">
            <SectionTitle
              subtitle="Our Services"
              title="Unlock Your Potential, <br/> How We Can Help You"
              description="Of course! Here are more compelling descriptions for each of Espres Global's services:"
              textAlignment="heading-light-left"
              textColor=""
            />
            <div className="row">
              <ServicePropOne
                colSize="col-xl-6 col-md-6"
                serviceStyle=""
                itemShow="6"
              />
            </div>
          </div>
        </div>
        {/* <AboutOne /> */}
        <ProjectOne />
        {/* <CounterUpOne /> */}
        {/* <TestimonialOne /> */}
        {/* <BrandOne /> */}
        {/* <BlogOne /> */}
        {/* <CtaLayoutOne /> */}
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Espres;
