import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";

// Home Pages Import
import DigitalAgency from "./pages/Espres";

// Blog Import
import BlogGridView from "./pages/BlogGrid";
import BlogCategory from "./pages/Category";
import BlogArchive from "./pages/Archive";
import BlogDetails from "./pages/BlogDetails";

// Service
import ServiceOne from "./pages/ServiceOne";
import ServiceTwo from "./pages/ServiceTwo";

// Project
import ProjectGridOne from "./pages/ProjectGridOne";
import ProjectDetails from "./pages/ProjectDetails";

// Pages
import AboutUs from "./pages/AboutUs";

import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import Testimonials from "./pages/Testimonials";

import Contact from "./pages/Contact";
import ErrorPage from "./pages/404";

// CSS Import
import "./assets/scss/app.scss";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route
            path={process.env.PUBLIC_URL + "/"}
            element={<DigitalAgency />}
          />

          {/* Blogs */}
          <Route
            path={process.env.PUBLIC_URL + "/blog-grid"}
            element={<BlogGridView />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/category/:slug"}
            element={<BlogCategory />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/archive/:slug"}
            element={<BlogArchive />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/blog-details/:id"}
            element={<BlogDetails />}
          />

          {/* Service */}
          <Route
            path={process.env.PUBLIC_URL + "/service-one"}
            element={<ServiceOne />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/service-two"}
            element={<ServiceTwo />}
          />

          {/* Project  */}
          <Route
            path={process.env.PUBLIC_URL + "/project-grid-one"}
            element={<ProjectGridOne />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/project-details/:slug"}
            element={<ProjectDetails />}
          />

          {/* Pages  */}
          <Route
            path={process.env.PUBLIC_URL + "/about-us"}
            element={<AboutUs />}
          />
          <Route path={process.env.PUBLIC_URL + "/team"} element={<Team />} />
          <Route
            path={process.env.PUBLIC_URL + "/team-details/:slug"}
            element={<TeamDetails />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/testimonials"}
            element={<Testimonials />}
          />

          <Route
            path={process.env.PUBLIC_URL + "/contact"}
            element={<Contact />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/404"}
            element={<ErrorPage />}
          />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
