import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderTwo";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectFive from "../component/project/ProjectFive";

const ProjectGridOne = () => {
  return (
    <>
      <SEO title="Project" />
      {/* <ColorSwitcher /> */}
      <main className="main-wrapper">
        <HeaderOne path={"/project-grid-one"} />
        <BcrumbBannerOne
          title="Our Impact: See How <br/> We've Helped Others."
          paragraph="Witness the impact we deliver: Explore client success stories that ignite your own potential."
          styleClass=""
          mainThumb="/images/banner/banner-thumb-1.png"
        />
        <ProjectFive />
        {/* <CtaLayoutOne /> */}
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default ProjectGridOne;
