import React from "react";
import Tilty from "react-tilty";
import { Link } from "react-router-dom";

const BcrumbBannerOne = ({
  title,
  paragraph,
  styleClass,
  mainThumb,
  showBtn,
}) => {
  return (
    <div className="breadcrum-area breadcrumb-banner">
      <div className="container">
        <div className="section-heading heading-left">
          <h3
            className="title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h3>
          <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>
          <br />
          <br />
          {showBtn ? (
            <Link
              to={process.env.PUBLIC_URL + "/contact"}
              className="axil-btn btn-fill-primary"
            >
              Let's Create
            </Link>
          ) : (
            <div></div>
          )}
        </div>
        <div className={`banner-thumbnail ${styleClass}`}>
          {/* <Tilty perspective={2000} reset={false}>
            <img src={process.env.PUBLIC_URL + mainThumb} alt="Illustration" />
          </Tilty> */}
        </div>
      </div>
    </div>
  );
};

export default BcrumbBannerOne;
