import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";

const BannerOne = () => {
  return (
    <div className="banner banner-style-1">
      <div className="container">
        <div className="row align-items-end align-items-md-start">
          <div className="col-lg-6">
            <div className="banner-content">
              <AnimationOnScroll
                animateIn="fadeInUp"
                animateOnce={true}
                delay={100}
              >
                <p className="title">
                  Transform Your Vision into Digital Reality.
                </p>
                <span className="subtitle">
                  Leverage Espres Global's experienced team and tailored digital
                  solutions to bring your ideas to life quickly and gain a
                  competitive edge in your niche.
                </span>

                <Link
                  to={process.env.PUBLIC_URL + "/contact"}
                  className="axil-btn btn-fill-primary"
                >
                  Let's Create
                </Link>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-thumbnail">
              <AnimationOnScroll
                animateIn="zoomIn"
                duration={2}
                delay={300}
                animateOnce={true}
              >
                <div className="large-thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/images/banner/banner.svg"}
                    alt="banner"
                  />
                </div>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="slideInRight"
                duration={2}
                delay={300}
                animateOnce={true}
              >
                <div className="large-thumb-2">
                  {/* <img
                    src={
                      process.env.PUBLIC_URL + "/images/banner/Team-amico.svg"
                    }
                    alt="Laptop"
                  /> */}
                </div>
              </AnimationOnScroll>
              <ul className="list-unstyled shape-group">
                <li className="shape shape-1">
                  <AnimationOnScroll
                    animateIn="slideInLeft"
                    duration={1}
                    delay={800}
                    animateOnce={true}
                  >
                    {/* <img
                    src={process.env.PUBLIC_URL + "/images/banner/Company-amico.svg"}
                    alt="Laptop"
                  /> */}
                  </AnimationOnScroll>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerOne;
