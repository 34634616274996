import React from "react";
import { Link } from "react-router-dom";

const PropOne = ({ projectStyle, portfolio }) => {
  return (
    <>
      <div className={`project-grid ${projectStyle}`}>
        <div className="thumbnail">
          <Link to={portfolio.url}>
            <img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
          </Link>
        </div>
        <div className="content">
          <h5 className="title">
            <Link to={portfolio.url}>{portfolio.title}</Link>
          </h5>
          <span className="subtitle">
            {portfolio.category.map((cat, i) => (
              <span key={i}>{cat}</span>
            ))}
          </span>
          <p className="description">
            <br />
            {portfolio.description}
          </p>
        </div>
      </div>
    </>
  );
};

export default PropOne;
