import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const AboutFour = () => {
  return (
    <div className="section section-padding case-study-featured-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="case-study-featured-thumb">
              <img
                src={process.env.PUBLIC_URL + "/images/about/who.svg"}
                alt="travel"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <span className="subtitle">Who we are</span>
                <h3 className="title">Building software for world changers</h3>
                <p>
                  Espres is a digital solutions company offering a range of
                  services including software development, product strategy &
                  research, branding, and digital marketing. We help businesses
                  establish and enhance their online presence in Nigeria and
                  globally.
                </p>
                <p>
                  Our mission is to be a leading digital services provider,
                  delivering solutions that drive technological and economic
                  growth across Africa.
                </p>
              </div>
              <div className="case-study-counterup">
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? <CountUp end="10" duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">Years on the market</span>
                </div>
                <div className="single-counterup">
                  <h2 className="count-number">
                    <TrackVisibility once>
                      {({ isVisible }) => (
                        <span className="number count">
                          {isVisible ? <CountUp end="20" duration={1} /> : null}
                        </span>
                      )}
                    </TrackVisibility>
                    <span className="symbol">+</span>
                  </h2>
                  <span className="counter-title">
                    Projects delivered so far
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
