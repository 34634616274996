import React from "react";

const ServiceSection = ({ subtitle, title, imagePath }) => {
  return (
    <div className="section-padding-equal">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="service-section">
              <div className="service-section-heading heading-left">
                <span className="subtitle">{subtitle}</span>
                <h6 className="title">{title}</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="service-section">
              <div className="thumbnail">
                <img src={process.env.PUBLIC_URL + imagePath} alt="thumbnail" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
