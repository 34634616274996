import React from "react";
import { Link } from "react-router-dom";

const Nav = ({ path }) => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li>
          <Link
            to={process.env.PUBLIC_URL + "/about-us"}
            className={`menu-nav-item ${
              path === "/about-us" ? "active-nav" : ""
            }`}
          >
            Why Espres?
          </Link>
        </li>
        <li>
          <Link
            to={process.env.PUBLIC_URL + "/service-two"}
            className={`menu-nav-item ${
              path === "/service-two" ? "active-nav" : ""
            }`}
          >
            Our Services
          </Link>
        </li>
        <li>
          <Link
            to={process.env.PUBLIC_URL + "/project-grid-one"}
            className={`menu-nav-item ${
              path === "/project-grid-one" ? "active-nav" : ""
            }`}
          >
            Projects
          </Link>
        </li>
        <li>
          <Link
            to="https://esprescommunity.wordpress.com"
            className={`menu-nav-item ${path === "/blog" ? "active-nav" : ""}`}
          >
            Blog
          </Link>
        </li>
        <li>
          <Link
            to={process.env.PUBLIC_URL + "/contact"}
            className={`menu-nav-item ${
              path === "/contact" ? "active-nav" : ""
            }`}
          >
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
