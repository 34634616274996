import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderTwo from "../common/header/HeaderTwo";
import SEO from "../common/SEO";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import ProcessOne from "../component/process/ProcessOne";
import AboutThree from "../component/about/AboutThree";
import AboutFour from "../component/about/AboutFour";
import AboutFive from "../component/about/AboutFive";

const AboutUs = () => {
  return (
    <>
      <SEO title="About us" />
      {/* <ColorSwitcher /> */}
      <main className="main-wrapper">
        <HeaderTwo path={"/about-us"} />
        <BcrumbBannerOne
          title="The Espres Difference, <br/> What Sets Us Apart"
          paragraph="Our expertise takes validated ideas and crafts digital products that deliver success for you and your target audience"
          styleClass="thumbnail-4"
          mainThumb="/images/banner/banner-thumb-3.png"
        />
        <AboutFour />
        {/* <AboutThree /> */}
        <AboutFive />
        {/* <ProcessOne /> */}
        {/* <CtaLayoutOne /> */}
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default AboutUs;
