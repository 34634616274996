import React from "react";
import { Link } from "react-router-dom";

const AboutFive = () => {
  return (
    <div className="section-padding-equal">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-team">
              <div className="section-heading heading-left">
                <span className="subtitle">Our Team</span>
                <h3 className="title">
                  Alone we can do so little; together we can do so much.
                </h3>
                <p>
                  Harnessing the Power of Unity for Greater Achievements. When
                  we join forces, our combined efforts amplify our impact.
                  Together, we can achieve extraordinary results that are
                  impossible to accomplish alone.
                </p>
                {/* <Link to="#" className="axil-btn btn-large btn-fill-primary">
                  Our Team
                </Link> */}
              </div>
            </div>
          </div>

          {/* <div className="col-lg-6">
            <div className="about-team">
              <div className="case-study-featured-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/team.svg"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div> */}
          <div className="col-lg-6">
            <div className="about-team">
              <div className="thumbnail">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/team.svg"}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFive;
